import * as React from "react"

import { ContactFormContextProvider } from "../core/contexts/contactForm"
import Layout from "../components/layout/layout"
import Seo from "../components/seo/seo"
import ContactForm from "../components/contactForm/contactForm"
import Divider from "../components/divider/divider"
import TextButton from "../components/buttons/textButton"
import Card from "../components/card/card"

const ContactPage = () => {
  return (
    <Layout>
      <Seo title="Contact" />
      <section className="container mt-5">
        <div className="mw-800 mb-5">
          <h4>Contact Us</h4>
          <p className="subtitle1">
            Thank you for your interest in iPhupha Digital. Please fill out the
            form below to ask a question or report a technical problem.
          </p>
        </div>
        <Divider />
        <div className="mt-5 mw-800">
          <p>Required fields are marked with an asterisk (*).</p>
          <Card canHover={false}>
            <div className="pt-4">
              <ContactFormContextProvider>
                <ContactForm />
              </ContactFormContextProvider>
            </div>
          </Card>
        </div>
        <div className="mw-800 mt-5 mb-5">
          <h6>More information</h6>
          <TextButton
            hasNoPaddingStart={true}
            label="info@iphupha.co.za"
            href="mailto:info@iphupha.co.za"
          />
          <br />
          <TextButton
            hasNoPaddingStart={true}
            label="+27 87 147 3276"
            href="tel:+27 87 147 3276"
          />
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage
